import axios from "axios";
import { validateSignInInputs } from "../../../auth/Validator";

export const signIn = async (usernameEmail, password) => {

    const validationResult = validateSignInInputs(usernameEmail, password);

    if (validationResult !== true) {
        return { success: false, error: validationResult };
    }

    try {
        const { data } = await axios.post(process.env.REACT_APP_SIGN_IN, {
            usernameOrEmailAddress: usernameEmail.trim(),
            password: password
        });

        const messages = {
            "Bad Credentials": "The provided credentials are incorrect.",
            "Email address is not confirmed": "Please confirm your email address before signing in.",
            "Account is Locked": "Your account has been locked. Please contact support.",
            "No privileges": "You do not have the appropriate permissions"
        };

        if (messages[data]) {
            return { success: false, error: messages[data] };
        } else {
            return { success: true, data };
        }
    } catch (error) {
        return { success: false, error: "Username or email address does not exist" };
    }
}

export const signInGoogle = async (credentials) => {
    try {
        const { data } = await axios.post(process.env.REACT_APP_SIGN_IN_GOOGLE, {
            aud: credentials.aud,
            azp: credentials.azp,
            email: credentials.email,
            email_verified: credentials.email_verified,
            exp: credentials.exp,
            family_name: credentials.family_name,
            given_name: credentials.given_name,
            iat: credentials.iat,
            iss: credentials.iss,
            jti: credentials.jti,
            name: credentials.name,
            nbf: credentials.nbf,
            picture: credentials.picture,
            sub: credentials.sub
        });

        const messages = {
            "Bad Credentials": "The provided credentials are incorrect.",
            "Email address is not confirmed": "Please confirm your email address before signing in.",
            "Account is Locked": "Your account has been locked. Please contact support.",
            "No privileges": "You do not have the appropriate permissions",
            "Sign up": "Sign up"
        };

        if (messages[data]) {
            return { success: false, error: messages[data] };
        } else {
            return { success: true, data };
        }
    } catch (error) {
        return { success: false, error: "Username or email address does not exist" };
    }
}