import {useLocation, useNavigate} from "react-router-dom";
import Page404 from "../../pages/404";
import React, {useEffect, useState} from "react";
import {Popup} from "../../popup/Popup";
import * as Countries from "../Countries";
import * as SignUpHandler from "./SignUpHandler";
import {useAuth} from "../../auth/AuthContext";

const SignUp = () => {

    const [fullName, setFullName] = useState("");
    const [username, setUsername] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [countriesList, setCountriesList] = useState([]);
    const [country, setCountry] = useState("");
    const [error, setError] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const { setTokenValue, setUserRoleValue } = useAuth();
    const { credentials } = location.state || {};

    useEffect(() => {
        Countries.countries().then(result => setCountriesList(result.success ? (result.data === undefined ? [] : result.data) : []));

        if (credentials !== undefined) {
            setFullName(credentials.name);
            setEmailAddress(credentials.email.toLowerCase());
        }
        window.history.replaceState({}, "")
    }, [credentials]);

    const handleSignUp = (event) => {
        event.preventDefault();

        SignUpHandler.signUp(
            fullName,
            username,
            emailAddress,
            password,
            confirmPassword,
            credentials.picture,
            birthdate,
            country,
            credentials.email_verified ? 1 : 0
        ).then(result => {
            if (result.success) {
                const [data1, data2] = result.data;
                setTokenValue(data1);
                setUserRoleValue("ROLE_USER");
                navigate(`/${data2}`);
                return;
            } else {
                setError(result.error);
            }
            setModalOpen(true);
        });
    }

    if (!credentials) {
        return <Page404 />;
    }

    return (
        <>
            <section id={"sign-in"} className={"container form d-flex align-items-center mt-5"}>
                <div className={"container"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-lg-5 text-center"}>
                            <form method={"post"} className={"input-form p-3 p-md-4"} onSubmit={handleSignUp}>
                                <h1>Sign up</h1>
                                <div className={"form-group mt-2"}>
                                    <input type={"text"} className={"input form-control mt-1"} name={"fullName"}
                                           placeholder={"Enter full name"}
                                           value={fullName}
                                           disabled={true}
                                    />
                                </div>
                                <div className={"form-group mt-2"}>
                                    <input type={"text"} className={"input form-control mt-1"} name={"username"}
                                           placeholder={"Enter username"}
                                           value={username}
                                           autoComplete={"username"}
                                           onChange={(event) => setUsername(event.target.value)}/>
                                </div>
                                <div className={"form-group mt-2"}>
                                    <input type={"text"} className={"input form-control mt-1"} name={"emailAddress"}
                                           placeholder={"Enter email address"}
                                           value={emailAddress}
                                           autoComplete={"email"}
                                           disabled={true}
                                    />
                                </div>
                                <div className={"form-group mt-2"}>
                                    <input type={"password"} className={"input form-control mt-1"} name={"password"}
                                           placeholder={"Enter password"}
                                           value={password}
                                           autoComplete={"new-password"}
                                           onChange={(event) => setPassword(event.target.value)}/>
                                </div>
                                <div className={"form-group mt-2"}>
                                    <input type={"password"} className={"input form-control mt-1"} name={"confirmPassword"}
                                           placeholder={"Re-enter password"}
                                           value={confirmPassword}
                                           autoComplete={"new-password"}
                                           onChange={(event) => setConfirmPassword(event.target.value)}/>
                                </div>
                                <div className={"form-group mt-2"}>
                                    <input type={"date"} className={"input form-control mt-1"} name={"birthdate"}
                                           placeholder={"Enter birthdate"}
                                           value={birthdate}
                                           onChange={(event) => setBirthdate(event.target.value)}/>
                                </div>
                                <div className={"form-group mt-2"}>
                                    <select className={"input form-control mt-1"} name={"country"}
                                            value={country}
                                            onChange={(event) => setCountry(event.target.value)}>
                                        <option value={""} disabled>Select country</option>
                                        {countriesList.map((countryItem, index) => (
                                            <option key={index} value={countryItem.name}>{countryItem.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={"text-center mt-2"}>
                                    <button type={"submit"} className={"button"}>Sign up</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Popup trigger={modalOpen} setTrigger={setModalOpen}>
                <div className={"popup form"}>
                    <div className={"text-center"}>
                        <h3>Sign up</h3>
                        <p>{error}</p>
                    </div>
                    <div className={"text-right"}>
                        <div className={"input-form"}>
                            <button type={"submit"} className={"button mt-2 mb-2"} onClick={() => setModalOpen(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    );
}

export default SignUp;
